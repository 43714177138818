import React from "react";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image"
import BlockContent from "@sanity/block-content-to-react"
import Layout from '../../layouts'

const PageProjecten = ({ data }) => {
  const page = data.page
  return (
    <Layout>
      <Helmet>
        <title>{page.title} - Surventure Camperbouw</title>
        <meta name="description" content={page.metaDescription} />
      </Helmet>
      <section className="panel content-text">
        {page.title &&
          <h1>{page.title}</h1>
        }
        {page._rawIntro &&
          <div className="intro">
            <BlockContent blocks={page._rawIntro} />
          </div>
        }
        {page._rawBody &&
          <BlockContent blocks={page._rawBody} />
        }
      </section>
      <section id="lijst" className="panel panel--xlarge portfolio-list">
        {page.projects && page.projects.map(function(project, index){
          return (
            <article key={index} className="portfolio-list__item">
                <div className={index%2 === 0 ? 'row order-even block v-align-top' : 'row order-odd block v-align-top'}>
                    <div className="small-12 medium-6 large-8 columns content-img">
                      <Link to={`/projecten/${project.slug.current}`}>
                        <Img fluid={project.listImage.asset.fluid} alt={project.title} />
                      </Link>
                    </div>
                    <div className="small-12 medium-6 large-4 columns content-text">
                      <h2 className="portfolio-list__item__title heading-beta"><Link to={`/projecten/${project.slug.current}`}>{project.title}</Link></h2>
                      {project._rawDescriptionShort &&
                        <div className="portfolio-list__item__description">
                          <BlockContent blocks={project._rawDescriptionShort} />
                        </div>
                      }
                      <Link to={`/projecten/${project.slug.current}`}>Bekijk dit project</Link>
                    </div>
                </div>
            </article>
          );
        })}
      </section>
    </Layout>
  )
}

export default PageProjecten

export const query = graphql`
  query {
    page: sanityProjects {
      title
      metaDescription
      _rawIntro
      _rawBody
      projects {
        id
        title
        slug {
          current
        }
        type
        _rawDescriptionShort
        listImage {
          asset {
            fluid(maxWidth: 1000) {
              ...GatsbySanityImageFluid_withWebp
            }
          }
        }
      }
    }
  }
`

